
const digest = 'a74355ce9f092d64ed4236ace53c06e08a6739ee2aeb6ed31bd5c90d1f4292ac';
const css = `.schedule .body-content {
  padding: 0;
}
`;

(function() {
  if (typeof document === 'undefined') {
    return;
  }
  if (!document.getElementById(digest)) {
    var el = document.createElement('style');
    el.id = digest;
    el.textContent = css;
    document.head.appendChild(el);
  }
})();
    
export default {};
export { css, digest };
  