
const digest = '5106849fcbb7a052e63f5a1315eca52500e4eab342d6a234a754f1f719e50962';
const css = `._spinner_3vod1_1 {
  width: 2rem;
  height: 2rem;
  color: var(--color-brand);
}
`;

(function() {
  if (typeof document === 'undefined') {
    return;
  }
  if (!document.getElementById(digest)) {
    var el = document.createElement('style');
    el.id = digest;
    el.textContent = css;
    document.head.appendChild(el);
  }
})();
    
export default {"spinner":"_spinner_3vod1_1"};
export { css, digest };
  