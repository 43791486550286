
const digest = '27c142de1e68824302fb4fd71a9637be0a16499b30a17cc77b98baae238213ec';
const css = `._fieldGroupLabel_1y59t_1 {
  display: flex;
  align-items: center;
  justify-content: center;
  color: var(--color-neutral-10);
}
`;

(function() {
  if (typeof document === 'undefined') {
    return;
  }
  if (!document.getElementById(digest)) {
    var el = document.createElement('style');
    el.id = digest;
    el.textContent = css;
    document.head.appendChild(el);
  }
})();
    
export default {"fieldGroupLabel":"_fieldGroupLabel_1y59t_1"};
export { css, digest };
  